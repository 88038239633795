@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

.questionnaireWrapper {
  background: #fff;
  flex-grow: 1;
}

.questionnaireWrapper :global(.MuiTypography-body1) {
  font-family: "Roboto", sans-serif;
}

.checkboxQuestionnaireItem,
.checkboxQuestionnaireItemWhiteText {
  background-color: rgb(238, 237, 249);
  margin: 4px 0;
  line-height: 21.6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.checkboxQuestionnaireItem > label,
.checkboxQuestionnaireItemWhiteText > label {
  padding: 12px 16px;
  margin: 0;
}

.checkboxQuestionnaireItemWhiteText :global(.MuiFormControlLabel-label) {
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  color: rgb(255, 255, 255);
}

.checkboxCommentItem {
  background-color: rgb(238, 237, 249);
  width: 100%;
  padding: 0 16px;
}

.checkboxQuestionnaireItem :global(.MuiFormControlLabel-label) {
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  color: rgb(26, 31, 45);
}

@media (min-width: 768px) {
  .checkboxQuestionnaireItem:hover :global(.MuiFormControlLabel-label) {
    color: rgb(16, 6, 159);
  }
}

@media (max-width: 767px) {
  .checkboxQuestionnaire:global(.Mui-checked)
    + :global(.MuiFormControlLabel-label),
  .checkboxQuestionnaireRadio:global(.Mui-checked)
    + :global(.MuiFormControlLabel-label) {
    color: rgb(16, 6, 159);
  }
}

.checkboxQuestionnaire:hover,
.checkboxQuestionnaireRadio:hover {
  background-color: transparent !important;
}

.checkboxQuestionnaireItem .checkboxQuestionnaire,
.checkboxQuestionnaireItem .checkboxQuestionnaireRadio,
.checkboxQuestionnaireItemWhiteText .checkboxQuestionnaire,
.checkboxQuestionnaireItemWhiteText .checkboxQuestionnaireRadio {
  position: static;
  padding: 1px 9px 1px 0;
}

.checkboxQuestionnaireItem .checkboxQuestionnaire:before,
.checkboxQuestionnaireItem .checkboxQuestionnaireRadio:before,
.checkboxQuestionnaireItemWhiteText .checkboxQuestionnaire:before,
.checkboxQuestionnaireItemWhiteText .checkboxQuestionnaireRadio:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-radius: 4px;
}

.checkboxQuestionnaireItem .checkboxQuestionnaire:global(.Mui-checked):before,
.checkboxQuestionnaireItem
  .checkboxQuestionnaireRadio:global(.Mui-checked):before,
.checkboxQuestionnaireItemWhiteText
  .checkboxQuestionnaire:global(.Mui-checked):before,
.checkboxQuestionnaireItemWhiteText
  .checkboxQuestionnaireRadio:global(.Mui-checked):before {
  border-color: rgb(16, 6, 159);
}

.checkboxQuestionnaire :global(.MuiIconButton-label),
.checkboxQuestionnaireRadio :global(.MuiIconButton-label) {
  position: relative;
}

.checkboxQuestionnaire :global(.MuiIconButton-label):before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
  border: 1px solid rgb(16, 6, 159);
  background: #fff;
  border-radius: 2px;
}

.checkboxQuestionnaire svg {
  fill: rgb(16, 6, 159);
  z-index: 1;
  opacity: 0;
}

.checkboxQuestionnaire:global(.Mui-checked) svg {
  opacity: 1;
}

.questionsWrapper {
  width: 375px;
  max-width: 100%;
}

.largeQuestionItem {
  width: 700px;
}

@media (max-width: 400px) {
  .questionsWrapper {
    width: 300px;
  }
}

.checkboxQuestionnaireRadio svg {
  opacity: 0;
}

.checkboxQuestionnaireRadio :global(.MuiIconButton-label):before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
  border-color: rgb(16, 6, 159);
  border-style: solid;
  border-width: 1px;
  background: #fff;
  border-radius: 50%;
  transition: all 150ms ease 0s;
}

.checkboxQuestionnaireRadio:global(.Mui-checked)
  :global(.MuiIconButton-label):before {
  border-width: 5px;
}

/*Question preview*/
.titleStyles {
  font-weight: bold;
  font-size: 24px !important;
  color: rgb(16, 6, 159);
  line-height: 30px;
}
.title--style1 {
  font-weight: bold;
  font-size: 24px !important;
  color: rgb(16, 6, 159);
  line-height: 30px;
  margin-top: 32px;
  margin-bottom: 32px;
}
.title--style2 {
  font-weight: normal;
  font-size: 18px !important;
  color: black;
  line-height: 24px;
  margin-top: 26px;
  margin-bottom: 26px;
}
.title--style3 {
  font-weight: bold;
  font-size: 18px !important;
  color: black;
  line-height: 30px;
  margin-top: 32px;
  margin-bottom: 32px;
}
.title--hide {
  display: none;
}

.descriptionStyles {
  opacity: 0.5;
  margin-bottom: 10px;
  font-size: 18px;
  max-height: 270px;
  overflow: hidden;
  overflow-y: auto;
}
.description--style1 {
  opacity: 0.5;
  margin-bottom: 10px;
  font-size: 18px;
}
.description--style2 {
  opacity: 0.5;
  margin-bottom: 10px;
  font-size: 12px;
  font-style: italic;
}
.description--style3 {
  margin-bottom: 10px;
  font-size: 12px;
  color: darkgreen;
}
.description--hide {
  display: none;
}

.featuredImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.commentStyles {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 2px;
  font-size: 14px;
  color: rgba(16, 6, 159);
  max-height: 270px;
  overflow: hidden;
  overflow-y: auto;
}

.buttonLoading {
  position: absolute;
  color: #4c4fd9;
}

.containerWrapper {
  width: 100%;
  padding: 16px;
  min-height: 75vh;
  transform: rotate(0deg);
  overflow: auto;
}

.buttonsWrapper {
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.buttonSpace {
  margin-right: 10px;
}

.buttonContinue {
  width: 582px;
  max-width: 100%;
  background-color: #13056c;
  color: #fff !important;
  height: 48px;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 500;
}

.buttonContinue:hover {
  background-color: #2a1ed9;
}

.buttonBack {
  font-weight: bold;
  font-size: 18px;
  color: #767993;
  text-transform: capitalize;
  width: 112px;
  max-width: 100%;
  height: 48px;
}
.buttonBack:hover {
  background-color: #fff;
}

.signatureCanvas {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: calc(100% - 50px);
}

.signatureButtonField {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.6rem;
}

.buttonClear {
  font-weight: bold;
  font-size: 18px;
  color: #767993;
  text-transform: capitalize;
  width: 100px;
  max-width: 100%;
  height: 40px;
  border: solid 1px #767993;
}
.buttonClear:hover {
  color: rgb(16, 6, 159);
  border-color: rgb(16, 6, 159);
}

.yesNoWrapper {
  width: 100%;
}

.selectFieldsWrapper {
  width: 100%;
}

/*MultipleTexInput*/
.inputStyle:global(.MuiInput-underline):before {
  border-bottom-color: #d9e1ec;
  border-bottom-width: 2px;
}

.inputStyle:global(.MuiInputLabel-root) {
  color: #6e82a3;
  font-size: 18px;
  font-family: "SF UI Text Regular";
}

.inputStyle:global(.MuiInput-input) {
  font-size: 17px;
  font-family: "SF UI Text Regular";
}

.inputStyle:global(.MuiInput-input):after {
  border-bottom: 2px solid #d9e1ec;
}

.inputStyle:global(.MuiInputLabel-filled) {
  color: #6e82a3;
  font-size: 18px;
  transform: translate(0, 0) scale(0.75);
  white-space: nowrap;
}

.inputStyle:global(.MuiFilledInput-root) {
  border-radius: 5px;
  background-color: #d9e1ec5e;
  margin-top: 15.2px;
}

.inputStyle:global(.MuiFilledInput-root) > :global(.MuiFilledInput-input) {
  padding: 8px 12px;
}

.instruction_Alert {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  color: #ea0f0f;
  background-color: #fff;
}

.instruction_Warning {
  color: #f89d11;
  width: 100%;
  padding: 10px;
  margin: 20;
  background-color: #fff;
}

.instruction_Instruction {
  color: black;
  width: 100%;
  padding: 10px;
  margin: 20;
  background-color: #fff;
}

.instruction_Other {
  color: black;
  width: 100%;
  padding: 10px;
  margin: 20;
  background-color: #fff;
}

/*Importer*/
.box {
  border-style: dashed;
  border-radius: 25px;
  border-width: 2px;
  padding: 20px;
  border-color: grey;
  text-align: center;
  max-width: 275px;
  margin: auto;
}

.boxPreview {
  border-style: dashed;
  border-radius: 25px;
  border-width: 2px;
  padding: 20px 0;
  border-color: grey;
  text-align: center;
  margin: auto;
}

.thumbnail {
  width: 50px;
  margin: auto;
}

.button {
  padding: 5px 30px;
  margin: auto;
}

.file {
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  padding: 10px;
}

/*PhoneFieldInput*/
.phoneInputClass:focus {
  box-shadow: none !important;
}

.phoneInputClass > div:before {
  color: #ffffff !important;
}

.phoneInputClass:disabled {
  cursor: default !important;
}

/*TextFieldInput*/
.buttonVisibleWrapper {
  position: absolute;
  right: 0;
  bottom: 6px;
}

.buttonVisible {
  padding: 0;
}

.input {
  padding-right: 30px;
}

/* LabOrderQuestion */
.labOrderPrice {
  font-weight: bold;
  color: rgb(16, 6, 159);
}

.singleTestName {
  font-size: 1.2rem;
}

.selectedTest {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  border-radius: 15% / 50%;
  border-color: rgb(16, 6, 159);
}

.unselectedTest {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  border-radius: 20% / 50%;
}
