table.table-striped {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

.table-striped th {
  border: 1px solid #ddd;
}

.table-striped th,
td {
  text-align: left;
  padding: 5px;
  border-left: 1px solid #ddd;
}

.table-striped tr:nth-child(even) {
  background-color: #f2f2f2;
}

.loading-preview-wrapper {
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.8);
}

.loading-preview-visible {
  visibility: visible;
}

.loading-preview {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}
