html,
body,
#root,
.app {
  min-height: 100vh;
  width: 100%;
}

.app {
  display: flex;
  overflow: hidden;
  outline: none;
  width: 376px;
}

aside {
  width: 250px;
}

.palette {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}

.shape {
  margin: 10px;
  height: 100px;
  width: 150px;
  border: solid 2px black;
}

.circle {
  width: 100px;
  border-radius: 50%;
}

.panel {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.properties {
  margin-top: 10px;
}

.key {
  color: lightslategray;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  height: 25px;
}

.value {
  color: darkslategray;
  font-weight: bolder;
}

.no-data {
  color: darkslategray;
}

.canvas {
  /*width: calc(100% - 200px);*/
  /*border: solid 1px red;*/
  /*border-top: none;*/
  /*border-bottom: none;*/
  position: relative;
  /*background-image: url(https://images.indianexpress.com/2017/04/nature-tree_759.jpg);*/
  background-repeat: no-repeat;
  background-size: contain;
}
