@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
.Questionnaire_questionnaireWrapper__yebNe {
  background: #fff;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.Questionnaire_questionnaireWrapper__yebNe .MuiTypography-body1 {
  font-family: "Roboto", sans-serif;
}

.Questionnaire_checkboxQuestionnaireItem__R1Nce,
.Questionnaire_checkboxQuestionnaireItemWhiteText__qSQSl {
  background-color: rgb(238, 237, 249);
  margin: 4px 0;
  line-height: 21.6px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  text-align: left;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.Questionnaire_checkboxQuestionnaireItem__R1Nce > label,
.Questionnaire_checkboxQuestionnaireItemWhiteText__qSQSl > label {
  padding: 12px 16px;
  margin: 0;
}

.Questionnaire_checkboxQuestionnaireItemWhiteText__qSQSl .MuiFormControlLabel-label {
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  color: rgb(255, 255, 255);
}

.Questionnaire_checkboxCommentItem__xJ6pC {
  background-color: rgb(238, 237, 249);
  width: 100%;
  padding: 0 16px;
}

.Questionnaire_checkboxQuestionnaireItem__R1Nce .MuiFormControlLabel-label {
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  color: rgb(26, 31, 45);
}

@media (min-width: 768px) {
  .Questionnaire_checkboxQuestionnaireItem__R1Nce:hover .MuiFormControlLabel-label {
    color: rgb(16, 6, 159);
  }
}

@media (max-width: 767px) {
  .Questionnaire_checkboxQuestionnaire__I6M7O.Mui-checked
    + .MuiFormControlLabel-label,
  .Questionnaire_checkboxQuestionnaireRadio__ViIhm.Mui-checked
    + .MuiFormControlLabel-label {
    color: rgb(16, 6, 159);
  }
}

.Questionnaire_checkboxQuestionnaire__I6M7O:hover,
.Questionnaire_checkboxQuestionnaireRadio__ViIhm:hover {
  background-color: transparent !important;
}

.Questionnaire_checkboxQuestionnaireItem__R1Nce .Questionnaire_checkboxQuestionnaire__I6M7O,
.Questionnaire_checkboxQuestionnaireItem__R1Nce .Questionnaire_checkboxQuestionnaireRadio__ViIhm,
.Questionnaire_checkboxQuestionnaireItemWhiteText__qSQSl .Questionnaire_checkboxQuestionnaire__I6M7O,
.Questionnaire_checkboxQuestionnaireItemWhiteText__qSQSl .Questionnaire_checkboxQuestionnaireRadio__ViIhm {
  position: static;
  padding: 1px 9px 1px 0;
}

.Questionnaire_checkboxQuestionnaireItem__R1Nce .Questionnaire_checkboxQuestionnaire__I6M7O:before,
.Questionnaire_checkboxQuestionnaireItem__R1Nce .Questionnaire_checkboxQuestionnaireRadio__ViIhm:before,
.Questionnaire_checkboxQuestionnaireItemWhiteText__qSQSl .Questionnaire_checkboxQuestionnaire__I6M7O:before,
.Questionnaire_checkboxQuestionnaireItemWhiteText__qSQSl .Questionnaire_checkboxQuestionnaireRadio__ViIhm:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-radius: 4px;
}

.Questionnaire_checkboxQuestionnaireItem__R1Nce .Questionnaire_checkboxQuestionnaire__I6M7O.Mui-checked:before,
.Questionnaire_checkboxQuestionnaireItem__R1Nce
  .Questionnaire_checkboxQuestionnaireRadio__ViIhm.Mui-checked:before,
.Questionnaire_checkboxQuestionnaireItemWhiteText__qSQSl
  .Questionnaire_checkboxQuestionnaire__I6M7O.Mui-checked:before,
.Questionnaire_checkboxQuestionnaireItemWhiteText__qSQSl
  .Questionnaire_checkboxQuestionnaireRadio__ViIhm.Mui-checked:before {
  border-color: rgb(16, 6, 159);
}

.Questionnaire_checkboxQuestionnaire__I6M7O .MuiIconButton-label,
.Questionnaire_checkboxQuestionnaireRadio__ViIhm .MuiIconButton-label {
  position: relative;
}

.Questionnaire_checkboxQuestionnaire__I6M7O .MuiIconButton-label:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
  border: 1px solid rgb(16, 6, 159);
  background: #fff;
  border-radius: 2px;
}

.Questionnaire_checkboxQuestionnaire__I6M7O svg {
  fill: rgb(16, 6, 159);
  z-index: 1;
  opacity: 0;
}

.Questionnaire_checkboxQuestionnaire__I6M7O.Mui-checked svg {
  opacity: 1;
}

.Questionnaire_questionsWrapper__61p4M {
  width: 375px;
  max-width: 100%;
}

.Questionnaire_largeQuestionItem__\+HqaD {
  width: 700px;
}

@media (max-width: 400px) {
  .Questionnaire_questionsWrapper__61p4M {
    width: 300px;
  }
}

.Questionnaire_checkboxQuestionnaireRadio__ViIhm svg {
  opacity: 0;
}

.Questionnaire_checkboxQuestionnaireRadio__ViIhm .MuiIconButton-label:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
  border-color: rgb(16, 6, 159);
  border-style: solid;
  border-width: 1px;
  background: #fff;
  border-radius: 50%;
  -webkit-transition: all 150ms ease 0s;
  transition: all 150ms ease 0s;
}

.Questionnaire_checkboxQuestionnaireRadio__ViIhm.Mui-checked
  .MuiIconButton-label:before {
  border-width: 5px;
}

/*Question preview*/

.Questionnaire_titleStyles__rXhPo {
  font-weight: bold;
  font-size: 24px !important;
  color: rgb(16, 6, 159);
  line-height: 30px;
}

.Questionnaire_title--style1__iHjYI {
  font-weight: bold;
  font-size: 24px !important;
  color: rgb(16, 6, 159);
  line-height: 30px;
  margin-top: 32px;
  margin-bottom: 32px;
}

.Questionnaire_title--style2__SkGDI {
  font-weight: normal;
  font-size: 18px !important;
  color: black;
  line-height: 24px;
  margin-top: 26px;
  margin-bottom: 26px;
}

.Questionnaire_title--style3__7hDCD {
  font-weight: bold;
  font-size: 18px !important;
  color: black;
  line-height: 30px;
  margin-top: 32px;
  margin-bottom: 32px;
}

.Questionnaire_title--hide__\+ITVS {
  display: none;
}

.Questionnaire_descriptionStyles__Fnnjg {
  opacity: 0.5;
  margin-bottom: 10px;
  font-size: 18px;
  max-height: 270px;
  overflow: hidden;
  overflow-y: auto;
}

.Questionnaire_description--style1__hcNLd {
  opacity: 0.5;
  margin-bottom: 10px;
  font-size: 18px;
}

.Questionnaire_description--style2__DDb7m {
  opacity: 0.5;
  margin-bottom: 10px;
  font-size: 12px;
  font-style: italic;
}

.Questionnaire_description--style3__Sd\+Vy {
  margin-bottom: 10px;
  font-size: 12px;
  color: darkgreen;
}

.Questionnaire_description--hide__UgiXe {
  display: none;
}

.Questionnaire_featuredImage__vCbDq {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.Questionnaire_commentStyles__lXGlN {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 2px;
  font-size: 14px;
  color: rgba(16, 6, 159);
  max-height: 270px;
  overflow: hidden;
  overflow-y: auto;
}

.Questionnaire_buttonLoading__MT1Jp {
  position: absolute;
  color: #4c4fd9;
}

.Questionnaire_containerWrapper__3Q8\+2 {
  width: 100%;
  padding: 16px;
  min-height: 75vh;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  overflow: auto;
}

.Questionnaire_buttonsWrapper__Xj0P9 {
  padding: 16px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.Questionnaire_buttonSpace__yzCEu {
  margin-right: 10px;
}

.Questionnaire_buttonContinue__MSKRl {
  width: 582px;
  max-width: 100%;
  background-color: #13056c;
  color: #fff !important;
  height: 48px;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 500;
}

.Questionnaire_buttonContinue__MSKRl:hover {
  background-color: #2a1ed9;
}

.Questionnaire_buttonBack__UxaJC {
  font-weight: bold;
  font-size: 18px;
  color: #767993;
  text-transform: capitalize;
  width: 112px;
  max-width: 100%;
  height: 48px;
}

.Questionnaire_buttonBack__UxaJC:hover {
  background-color: #fff;
}

.Questionnaire_signatureCanvas__eX\+LI {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  width: 100%;
  height: calc(100% - 50px);
}

.Questionnaire_signatureButtonField__IrRQl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-top: 0.6rem;
}

.Questionnaire_buttonClear__UZCmJ {
  font-weight: bold;
  font-size: 18px;
  color: #767993;
  text-transform: capitalize;
  width: 100px;
  max-width: 100%;
  height: 40px;
  border: solid 1px #767993;
}

.Questionnaire_buttonClear__UZCmJ:hover {
  color: rgb(16, 6, 159);
  border-color: rgb(16, 6, 159);
}

.Questionnaire_yesNoWrapper__0t3YG {
  width: 100%;
}

.Questionnaire_selectFieldsWrapper__Ui1E4 {
  width: 100%;
}

/*MultipleTexInput*/

.Questionnaire_inputStyle__vke1s.MuiInput-underline:before {
  border-bottom-color: #d9e1ec;
  border-bottom-width: 2px;
}

.Questionnaire_inputStyle__vke1s.MuiInputLabel-root {
  color: #6e82a3;
  font-size: 18px;
  font-family: "SF UI Text Regular";
}

.Questionnaire_inputStyle__vke1s.MuiInput-input {
  font-size: 17px;
  font-family: "SF UI Text Regular";
}

.Questionnaire_inputStyle__vke1s.MuiInput-input:after {
  border-bottom: 2px solid #d9e1ec;
}

.Questionnaire_inputStyle__vke1s.MuiInputLabel-filled {
  color: #6e82a3;
  font-size: 18px;
  -webkit-transform: translate(0, 0) scale(0.75);
          transform: translate(0, 0) scale(0.75);
  white-space: nowrap;
}

.Questionnaire_inputStyle__vke1s.MuiFilledInput-root {
  border-radius: 5px;
  background-color: #d9e1ec5e;
  margin-top: 15.2px;
}

.Questionnaire_inputStyle__vke1s.MuiFilledInput-root > .MuiFilledInput-input {
  padding: 8px 12px;
}

.Questionnaire_instruction_Alert__2SPP\+ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  padding: 10px;
  color: #ea0f0f;
  background-color: #fff;
}

.Questionnaire_instruction_Warning__GB8Mr {
  color: #f89d11;
  width: 100%;
  padding: 10px;
  margin: 20;
  background-color: #fff;
}

.Questionnaire_instruction_Instruction__HT-SO {
  color: black;
  width: 100%;
  padding: 10px;
  margin: 20;
  background-color: #fff;
}

.Questionnaire_instruction_Other__\+auF\+ {
  color: black;
  width: 100%;
  padding: 10px;
  margin: 20;
  background-color: #fff;
}

/*Importer*/

.Questionnaire_box__O2yVJ {
  border-style: dashed;
  border-radius: 25px;
  border-width: 2px;
  padding: 20px;
  border-color: grey;
  text-align: center;
  max-width: 275px;
  margin: auto;
}

.Questionnaire_boxPreview__ZZAW9 {
  border-style: dashed;
  border-radius: 25px;
  border-width: 2px;
  padding: 20px 0;
  border-color: grey;
  text-align: center;
  margin: auto;
}

.Questionnaire_thumbnail__TysUP {
  width: 50px;
  margin: auto;
}

.Questionnaire_button__rQf9u {
  padding: 5px 30px;
  margin: auto;
}

.Questionnaire_file__57pmW {
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  padding: 10px;
}

/*PhoneFieldInput*/

.Questionnaire_phoneInputClass__VOGDk:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.Questionnaire_phoneInputClass__VOGDk > div:before {
  color: #ffffff !important;
}

.Questionnaire_phoneInputClass__VOGDk:disabled {
  cursor: default !important;
}

/*TextFieldInput*/

.Questionnaire_buttonVisibleWrapper__\+ni2w {
  position: absolute;
  right: 0;
  bottom: 6px;
}

.Questionnaire_buttonVisible__zFM37 {
  padding: 0;
}

.Questionnaire_input__1u1sU {
  padding-right: 30px;
}

/* LabOrderQuestion */

.Questionnaire_labOrderPrice__rlZfy {
  font-weight: bold;
  color: rgb(16, 6, 159);
}

.Questionnaire_singleTestName__ulDHu {
  font-size: 1.2rem;
}

.Questionnaire_selectedTest__mEMMs {
  width: 100%;
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-radius: 15% / 50%;
  border-color: rgb(16, 6, 159);
}

.Questionnaire_unselectedTest__R57\+r {
  width: 100%;
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-radius: 20% / 50%;
}

table.table-striped {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

.table-striped th {
  border: 1px solid #ddd;
}

.table-striped th,
td {
  text-align: left;
  padding: 5px;
  border-left: 1px solid #ddd;
}

.table-striped tr:nth-child(even) {
  background-color: #f2f2f2;
}

.loading-preview-wrapper {
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.8);
}

.loading-preview-visible {
  visibility: visible;
}

.loading-preview {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

html,
body,
#root,
.app {
  min-height: 100vh;
  width: 100%;
}

.app {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  outline: none;
  width: 376px;
}

aside {
  width: 250px;
}

.palette {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px 0;
}

.shape {
  margin: 10px;
  height: 100px;
  width: 150px;
  border: solid 2px black;
}

.circle {
  width: 100px;
  border-radius: 50%;
}

.panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 10px;
}

.properties {
  margin-top: 10px;
}

.key {
  color: lightslategray;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 25px;
}

.value {
  color: darkslategray;
  font-weight: bolder;
}

.no-data {
  color: darkslategray;
}

.canvas {
  /*width: calc(100% - 200px);*/
  /*border: solid 1px red;*/
  /*border-top: none;*/
  /*border-bottom: none;*/
  position: relative;
  /*background-image: url(https://images.indianexpress.com/2017/04/nature-tree_759.jpg);*/
  background-repeat: no-repeat;
  background-size: contain;
}

